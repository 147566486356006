import React, {Component} from "react"
import styles from "./Header.module.css"
import {Link} from "react-router-dom"
import {ReactComponent as LogoIcon} from "./parts/img/logo.svg"
import {ReactComponent as SignatureIcon} from "./parts/img/signature.svg"

interface Props {}

export class Header extends Component<Props, {}> {
    public render() {
        return (
            <header className={styles.root}>
                <div className={styles.wrapper}>
                    <h1 className={styles.h1}>
                        <LogoIcon className={styles.logo} />
                        <span className={styles.logoText}>
                            AMERICAN NATIONAL UNIVERSITY INTERNATIONAL
                        </span>
                    </h1>
                    {/* <p className={styles.middleText}>
                        International (F1-VISA) students{" "}
                        <a href="https://simple.klassdocs.com/anui-form/" className={styles.link}>
                            click here
                        </a>
                    </p> */}
                    <div className={styles.rightWrapper}>
                        <SignatureIcon className={styles.signatureIcon} />
                        <span className={styles.text}>APPLY TO ANUI</span>
                    </div>
                </div>
            </header>
        )
    }
}
